import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import {Link} from "gatsby";

const About = props => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="description" content="About" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About</h1>
          </header>
          <p>Hi, my name is Filip Reese. I've worked as a product manager and product marketing manager at three different and unique technology companies over the last decade spanning marketplaces, B2B SaaS and AgTech. At these companies I've had the opportunity to be mentored by some of the best product managers, designers, engineering leaders, investors, and business leaders in the industry. I've also had the uniqe experience of helping to build up product organizations at all three companies and in the last several years have been mentoring new product managers.</p>

          <p>I started Of Product Management as I way to share the the skills, techniques, processes, frameworks and ideas I've utilized and refined as a product manager. It's also a place for me to share musings on product management, business, technology, products and trends that I find interesting.</p>

          <p>I am passionate about the craft of product management and the potential impact it has on companies, products and the workplace. And, I hope some of what I share here can be utilized by others to have a bigger impact in their own lives.</p>

          <p>Checkout my <Link to="http://filipreese.com" className="post-link">personal website</Link> or <Link to="linkedin.com/en/filipreese">Linkedin</Link> to learn more about my past experience or you can <Link to="/contact">contact me.</Link></p>
        </div>
      </section>
    </div>
  </Layout>
);

export default About;